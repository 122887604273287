var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __asyncValues = (this && this.__asyncValues) || function (o) {
    if (!Symbol.asyncIterator) throw new TypeError("Symbol.asyncIterator is not defined.");
    var m = o[Symbol.asyncIterator], i;
    return m ? m.call(o) : (o = typeof __values === "function" ? __values(o) : o[Symbol.iterator](), i = {}, verb("next"), verb("throw"), verb("return"), i[Symbol.asyncIterator] = function () { return this; }, i);
    function verb(n) { i[n] = o[n] && function (v) { return new Promise(function (resolve, reject) { v = o[n](v), settle(resolve, reject, v.done, v.value); }); }; }
    function settle(resolve, reject, d, v) { Promise.resolve(v).then(function(v) { resolve({ value: v, done: d }); }, reject); }
};
// Converts the OpenAI API params + chat messages list + an optional AbortSignal into a shape that
// the fetch interface expects.
export const getOpenAiRequestOptions = (_a, messages, signal) => {
    var { apiKey, model } = _a, restOfApiParams = __rest(_a, ["apiKey", "model"]);
    return ({
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiKey}`,
        },
        method: 'POST',
        body: JSON.stringify(Object.assign(Object.assign({ model }, restOfApiParams), { messages, stream: true })),
        signal,
    });
};
const CHAT_COMPLETIONS_URL = 'https://api.openai.com/v1/chat/completions';
const textDecoder = new TextDecoder('utf-8');
// Takes a set of fetch request options and calls the onIncomingChunk and onCloseStream functions
// as chunks of a chat completion's data are returned to the client, in real-time.
export const openAiStreamingDataHandler = (requestOpts, onIncomingChunk, onCloseStream) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, e_1, _b, _c;
    var _d, _e;
    // Record the timestamp before the request starts.
    const beforeTimestamp = Date.now();
    // Initiate the completion request
    const response = yield fetch(CHAT_COMPLETIONS_URL, requestOpts);
    // If the response isn't OK (non-2XX HTTP code) report the HTTP status and description.
    if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.status} - ${response.statusText}`);
    }
    // A response body should always exist, if there isn't one something has gone wrong.
    if (!response.body) {
        throw new Error('No body included in POST response object');
    }
    let content = '';
    let role = '';
    try {
        for (var _f = true, _g = __asyncValues(response.body), _h; _h = yield _g.next(), _a = _h.done, !_a;) {
            _c = _h.value;
            _f = false;
            try {
                const newData = _c;
                // Decode the data
                const decodedData = textDecoder.decode(newData);
                // Split the data into lines to process
                const lines = decodedData.split(/(\n){2}/);
                // Parse the lines into chat completion chunks
                const chunks = lines
                    // Remove 'data:' prefix off each line
                    .map((line) => line.replace(/(\n)?^data:\s*/, '').trim())
                    // Remove empty lines and "[DONE]"
                    .filter((line) => line !== '' && line !== '[DONE]')
                    // Parse JSON string
                    .map((line) => JSON.parse(line));
                // Process each chunk and send an update to the registered handler.
                for (const chunk of chunks) {
                    // Avoid empty line after single backtick
                    const contentChunk = ((_d = chunk.choices[0].delta.content) !== null && _d !== void 0 ? _d : '').replace(/^`\s*/, '`');
                    // Most times the chunk won't contain a role, in those cases set the role to ""
                    const roleChunk = (_e = chunk.choices[0].delta.role) !== null && _e !== void 0 ? _e : '';
                    // Assign the new data to the rest of the data already received.
                    content = `${content}${contentChunk}`;
                    role = `${role}${roleChunk}`;
                    onIncomingChunk(contentChunk, roleChunk);
                }
            }
            finally {
                _f = true;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (!_f && !_a && (_b = _g.return)) yield _b.call(_g);
        }
        finally { if (e_1) throw e_1.error; }
    }
    onCloseStream(beforeTimestamp);
    // Return the fully-assembled chat completion.
    return { content, role };
});
export default openAiStreamingDataHandler;
