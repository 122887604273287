var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { getOpenAiRequestOptions, openAiStreamingDataHandler, } from './chat-stream-handler';
const MILLISECONDS_PER_SECOND = 1000;
// Utility method for transforming a chat message decorated with metadata to a more limited shape
// that the OpenAI API expects.
const officialOpenAIParams = ({ content, role, }) => ({ content, role });
// Utility method for transforming a chat message that may or may not be decorated with metadata
// to a fully-fledged chat message with metadata.
const createChatMessage = (_a) => {
    var _b;
    var { content, role } = _a, restOfParams = __rest(_a, ["content", "role"]);
    return ({
        content,
        role,
        timestamp: (_b = restOfParams.timestamp) !== null && _b !== void 0 ? _b : Date.now(),
        meta: Object.assign({ loading: false, responseTime: '', chunks: [] }, restOfParams.meta),
    });
};
// Utility method for updating the last item in a list.
const updateLastItem = (msgFn) => (currentMessages) => currentMessages.map((msg, i) => {
    if (currentMessages.length - 1 === i) {
        return msgFn(msg);
    }
    return msg;
});
export const useChatCompletion = (apiParams) => {
    const [messages, _setMessages] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [controller, setController] = React.useState(null);
    // Abort an in-progress streaming response
    const abortResponse = () => {
        if (controller) {
            controller.abort();
            setController(null);
        }
    };
    // Reset the messages list as long as a response isn't being loaded.
    const resetMessages = () => {
        if (!loading) {
            _setMessages([]);
        }
    };
    // Overwrites all existing messages with the list of messages passed to it.
    const setMessages = (newMessages) => {
        if (!loading) {
            _setMessages(newMessages.map(createChatMessage));
        }
    };
    // When new data comes in, add the incremental chunk of data to the last message.
    const handleNewData = (chunkContent, chunkRole) => {
        _setMessages(updateLastItem((msg) => ({
            content: `${msg.content}${chunkContent}`,
            role: `${msg.role}${chunkRole}`,
            timestamp: 0,
            meta: Object.assign(Object.assign({}, msg.meta), { chunks: [
                    ...msg.meta.chunks,
                    {
                        content: chunkContent,
                        role: chunkRole,
                        timestamp: Date.now(),
                    },
                ] }),
        })));
    };
    // Handles what happens when the stream of a given completion is finished.
    const closeStream = (beforeTimestamp) => {
        // Determine the final timestamp, and calculate the number of seconds the full request took.
        const afterTimestamp = Date.now();
        const diffInSeconds = (afterTimestamp - beforeTimestamp) / MILLISECONDS_PER_SECOND;
        const formattedDiff = diffInSeconds.toFixed(2) + ' sec.';
        // Update the messages list, specifically update the last message entry with the final
        // details of the full request/response.
        _setMessages(updateLastItem((msg) => (Object.assign(Object.assign({}, msg), { timestamp: afterTimestamp, meta: Object.assign(Object.assign({}, msg.meta), { loading: false, responseTime: formattedDiff }) }))));
    };
    const submitPrompt = React.useCallback((newMessages) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        // Don't let two streaming calls occur at the same time. If the last message in the list has
        // a `loading` state set to true, we know there is a request in progress.
        if ((_b = (_a = messages[messages.length - 1]) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.loading)
            return;
        // If the array is empty or there are no new messages submited, do not make a request.
        if (!newMessages || newMessages.length < 1) {
            return;
        }
        setLoading(true);
        // Update the messages list with the new message as well as a placeholder for the next message
        // that will be returned from the API.
        const updatedMessages = [
            ...messages,
            ...newMessages.map(createChatMessage),
            createChatMessage({
                content: '',
                role: '',
                timestamp: 0,
                meta: { loading: true },
            }),
        ];
        // Set the updated message list.
        _setMessages(updatedMessages);
        // Create a controller that can abort the entire request.
        const newController = new AbortController();
        const signal = newController.signal;
        setController(newController);
        // Define options that will be a part of the HTTP request.
        const requestOpts = getOpenAiRequestOptions(apiParams, updatedMessages
            // Filter out the last message, since technically that is the message that the server will
            // return from this request, we're just storing a placeholder for it ahead of time to signal
            // to the UI something is happening.
            .filter((m, i) => updatedMessages.length - 1 !== i)
            // Map the updated message structure to only what the OpenAI API expects.
            .map(officialOpenAIParams), signal);
        try {
            // Wait for all the results to be streamed back to the client before proceeding.
            yield openAiStreamingDataHandler(requestOpts, 
            // The handleNewData function will be called as new data is received.
            handleNewData, 
            // The closeStream function be called when the message stream has been completed.
            closeStream);
        }
        catch (err) {
            if (signal.aborted) {
                console.error(`Request aborted`, err);
            }
            else {
                console.error(`Error during chat response streaming`, err);
            }
        }
        finally {
            // Remove the AbortController now the response has completed.
            setController(null);
            // Set the loading state to false
            setLoading(false);
        }
    }), [messages]);
    return {
        messages,
        loading,
        submitPrompt,
        abortResponse,
        resetMessages,
        setMessages,
    };
};
